import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { getProfile, isAuthenticated } from "../services/auth"

const IndexPage = () => (
  <Layout>
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 bg-gray-100 shadow">
        <div className="px-4 py-6 sm:px-0">
          <div className="border-2 border-gray-200 rounded-lg h-96 bg-white">
            <div className="container mx-auto m-24">
              <div className="flex justify-center">
                <article className="prose prose-sm color-mine-shaft">
                  <h2>
                    Hello {isAuthenticated() ? getProfile().name : "world"}!
                  </h2>
                  <p className="text-l text-mine-shaft">
                    {isAuthenticated() ? (
                      <>
                        You are logged in, so head on to the <Link to="/app">Dashboard</Link> to get started.
                      </>
                    ) : (
                      <>
                        Please <Link to="/app">signup or login</Link> to get
                        started with Hydra or Inspect.
                      </>
                    )}
                  </p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </Layout>
)
export default IndexPage
